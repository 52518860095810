import { Header } from "antd/lib/layout/layout";
import RoutesMenu from "./Routing/RoutesMenu";
import Env from "env";
import UserOutlined from "@ant-design/icons/UserOutlined";
import styled from "styled-components";
import useUser from "services/hooks/useUser";

const IconCircle = styled.div`
    line-height: 1;
    background-color: #4d4d4d;
    border-radius: 50%;
    padding: 6px;
`;

interface Props {
    enableStore?: boolean;
}

function AppHeader({ enableStore = false }: Props = {}) {
    const [user] = useUser();
    return (
        <Header id="app-header">
            <div className="blured-underlay" />
            <div className="logo" />
            <RoutesMenu
                items={[
                    { label: "HOME", path: "/", asPath: "" },
                    { label: "PLAY NOW", path: "/playnow" },
                    ...(enableStore ? [{ label: "STORE", path: "/store" }] : []),
                    { label: "VOTE", path: "/vote" },
                    { label: "HIGHSCORES", path: "/highscores" },
                    { label: "DISCORD", href: Env.DISCORD_LINK },
                    {
                        path: "/account",
                        label: user ? (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: "64px",
                                }}
                            >
                                <IconCircle>
                                    <UserOutlined
                                        style={{
                                            marginRight: 1,
                                            marginBottom: 1,
                                        }}
                                    />
                                </IconCircle>
                            </div>
                        ) : "LOGIN",
                    },
                ]}
                style={{ background: "none", textAlign: 'center' }}
            />
        </Header>
    )
}

export default AppHeader;
