import { CartItem, CheckoutResponseItem, StoreItem } from "services/api/GraphqlTypes";
import Analytics from "./Analytics";
import { getCountdown } from "services/hooks/useCountdown";
import moment from "moment";

class StoreAnalytics {
    public viewItems(items: Partial<StoreItem>[]): void {
        Analytics.event("view_item_list", {
            currency: "Gold",
            item_list_name: "Store",
            items: items.map((item, index) => ({
                ...this.getItem(item),
                index: index,
            })),
        });
    }

    public viewItem(item: Partial<StoreItem>): void {
        Analytics.event("view_item", {
            currency: "Gold",
            item_list_name: "Store",
            items: [
                this.getItem(item),
            ],
        });
    }

    public closeViewItem(viewDuration: number, item: Partial<StoreItem>): void {
        Analytics.event("close_view_item", {
            currency: "Gold",
            item_list_name: "Store",
            value: viewDuration,
            items: [
                this.getItem(item),
            ],
        });
    }

    public previewImage(item: Partial<StoreItem>): void {
        Analytics.event("preview_image", {
            currency: "Gold",
            item_list_name: "Store",
            items: [
                this.getItem(item),
            ],
        });
    }

    public addToCart(newCartValue: number, item: CartItem): void {
        Analytics.event("add_to_cart", {
            currency: "Gold",
            item_list_name: "Store",
            value: newCartValue,
            items: [
                {
                    ...this.getItem(item.storeItem),
                    quantity: item.quantity,
                },
            ],
        });
    }

    public removeFromCart(newCartValue: number, item: CartItem): void {
        Analytics.event("remove_from_cart", {
            currency: "Gold",
            item_list_name: "Store",
            value: newCartValue,
            items: [
                {
                    ...this.getItem(item.storeItem),
                    quantity: item.quantity,
                },
            ],
        });
    }

    public emptyCart(items: CartItem[]): void {
        Analytics.event("empty_cart", {
            currency: "Gold",
            value: 0,
            items: items.map(item => ({
                ...this.getItem(item.storeItem),
                quantity: item.quantity,
            })),
        });
    }

    public checkout(items: Partial<CheckoutResponseItem>[]): void {
        Analytics.event("checkout", {
            currency: "Gold",
            item_list_name: "Store",
            value: items.reduce((sum, item) => sum + (item.price ?? 0), 0),
            items: items.map((item, index) => ({
                name: item.name,
                quantity: item.quantity,
                price: item.price?.toString(),
                index: index,
            })),
        });
    }

    private getItem(item: Partial<StoreItem>) {
        return {
            item_id: item.id?.toString(),
            item_name: item.name,
            price: item.price?.toString(),
            compare_at_price: item.compareAtPrice?.toString(),
            stock: item.stock?.toString(),
            expires_at_countdown: item.expiresAt ? getCountdown(moment.utc(item.expiresAt)) : undefined,
            categories: item.categoryIds,
        };
    }
}

export default new StoreAnalytics();
