import React from "react";
import { Alert, Button, Card, Form, Input, Row, Space, Spin } from "antd";
import UserOutlined from '@ant-design/icons/UserOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';
import useUser from "../../services/hooks/useUser";
import { LoginResponse, useLoginMutation, useLogoutMutation } from "../../services/api/GraphqlTypes";
import Title from "antd/lib/typography/Title";
import Link from "antd/lib/typography/Link";
import FlexSpace from "../Helpers/FlexSpace";
import { LogoutOutlined } from "@ant-design/icons";

function LoginWidget() {
    const [user, loading, { onLogin, onLogout }] = useUser();
    const [loginMutation, { loading: loginLoading, error }] = useLoginMutation({
        onError: () => { },
        onCompleted: data => {
            data.login && onLogin({ ...data.login } as LoginResponse);
        }
    });
    const [logoutMutation, { loading: logoutLoading }] = useLogoutMutation({
        onError: () => {},
        onCompleted: _ => onLogout()
    });

    return (
        <Card
            size="small"
            title={loading || user ? undefined : "Login"}
            style={loading ? { textAlign: "center" } : undefined}
        >
            {loading && <Spin size="large" />}

            {user && (
                <Row>
                    <Title
                        level={5}
                        style={{ marginBottom: 0 }}
                    >Welcome {user.name}</Title>

                    <FlexSpace />

                    <Button
                        size="small"
                        type="link"
                        style={{ marginRight: 6 }}
                        loading={logoutLoading}
                        disabled={logoutLoading}
                        onClick={() => logoutMutation()}
                        icon={<LogoutOutlined />}
                    />
                </Row>
            )}

            {!user && !loading && (
                <Form
                    name="login"
                    onFinish={data => {
                        try {
                            loginMutation({
                                variables: data
                            });
                        } catch (e) {
                            console.log("Exception caught");
                        }
                    }}
                >
                    {error && (
                        <Form.Item>
                            <Alert message={error.message} type="error" />
                        </Form.Item>
                    )}

                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: 'Please enter your username!' }]}
                    >
                        <Input
                            placeholder="Username"
                            autoComplete="username"
                            prefix={<UserOutlined />}
                            disabled={loginLoading}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please enter your password!' }]}
                    >
                        <Input.Password
                            placeholder="Password"
                            autoComplete="current-password"
                            prefix={<LockOutlined />}
                            disabled={loginLoading}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={loginLoading}
                            loading={loginLoading}
                            style={{ marginRight: 16 }}
                        >
                            Login
                        </Button>

                        <Link href="/playnow">Create Account</Link>
                    </Form.Item>
                </Form>
            )}
        </Card>
    )
};

export default LoginWidget;