import { Card, Typography } from "antd";
import type { CardSize } from "antd/lib/card";
import useUser from "services/hooks/useUser";
import withGuard from "components/Guards/withGuard";
import PincodeGuard from "components/Guards/PincodeGuard";
import ChangePasswordForm from "./ChangePasswordForm";
import useFlashValue from "services/hooks/useFlashValue";
import { useMemo } from "react";

interface Props {
    size?: CardSize;
}

function ManagePasswordWidget({ size }: Props) {
    const [user] = useUser();
    const [showSuccess, flashSuccess] = useFlashValue(false, 10000);

    const content = useMemo(() => {
        if (showSuccess) {
            return <SuccessMessage />;
        }
        if (user?.isEmailVerified) {
            return withGuard(PincodeGuard, { scope: "change-password" }, (props) => {
                return (
                    <>
                        <ChangePasswordForm
                            {...props}
                            onSuccess={() => flashSuccess(true)}
                        />
                    </>
                );
            });
        }
        return <ChangePasswordForm onSuccess={() => flashSuccess(true)} />;
    }, [user, showSuccess]);

    return (
        <Card
            size={size}
            title="Change Password"
        >
            {content}
        </Card>
    )
};

function SuccessMessage() {
    return (
        <Typography.Title level={5}>
            <Typography.Text type="success" style={{ marginRight: 4 }}>
                Success!
            </Typography.Text>
            Your password was changed.
        </Typography.Title>
    );
}

export default ManagePasswordWidget;
