import Col from "antd/lib/col";
import styled from "styled-components";
import dividerImage from "assets/images/playnow/divider-download.png";
import Env from "env";
import Platforms from "services/utils/Platforms";
import { useMemo } from "react";
import { BackgroundBlur, Divider, DownloadButton, HeaderMega, Link, LinkContainer, TitleMega, TitleMegaSub } from "./PlayNowCommon";
import Row from "antd/lib/grid/row";

const downloadLinkMacOS = encodeURI("https://cdn.enchantps.com/launcher/update/enchant-launcher-1.1.1-universal.dmg");
const downloadLinkWindows = encodeURI("https://cdn.enchantps.com/launcher/update/enchant-launcher Setup 1.1.1.exe");
const downloadLinkJar = encodeURI("https://api.enchantps.com/api/v2/client/direct/live");

const windowsButtonProps = {
    href: downloadLinkWindows,
    title: "Download for Windows",
};

const macButtonProps = {
    href: downloadLinkMacOS,
    title: "Download for Mac OS",
};

interface Props {
    justRegistered?: boolean;
    style?: React.CSSProperties;
}

function PlayNowDownload({ style = {}, justRegistered = false }: Props) {
    const platform = Platforms.getOS();

    const { mainButtonProps, secondaryButtonProps } = useMemo(() => {
        if (platform === "macOS" || platform === "iOS") {
            return {
                mainButtonProps: macButtonProps,
                secondaryButtonProps: windowsButtonProps
            }
        }

        return {
            mainButtonProps: windowsButtonProps,
            secondaryButtonProps: macButtonProps
        }
    }, [platform]);

    return (
        <Row
            align="middle"
            style={{
                textAlign: "center",
                backgroundColor: "rgba(0,0,0,0.7)",
                marginTop: -64,
                flex: 1,
            }}
        >
            <Col
                span={24}
                style={{ marginBottom: 16, ...style }}
            >
                {justRegistered ? (
                    <HeaderMega>
                        <TitleMega>You're all set!</TitleMega>
                        <TitleMegaSub>Now download and play!</TitleMegaSub>
                    </HeaderMega>
                ) : (
                    <TitleMega>Play Enchant Now</TitleMega>
                )}

                <Divider>
                    <img src={dividerImage} alt="Divider" />
                </Divider>

                <a href={mainButtonProps.href}>
                    <DownloadButton>
                        {mainButtonProps.title}
                    </DownloadButton>
                </a>

                <LinkContainer>
                    <Link href={secondaryButtonProps.href}>
                        {secondaryButtonProps.title}
                    </Link>
                </LinkContainer>

                {!justRegistered && (
                    <LinkContainer>
                        <Link href="/playnow/signup">
                            Create an Account
                        </Link>
                    </LinkContainer>
                )}

                <BackgroundBlur>
                    <img src={Env.BACKGROUND_IMAGE} />
                </BackgroundBlur>
            </Col>
        </Row>
    )
}

export default PlayNowDownload;
