import { Button, Card, Col, Row, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useMemo, useState } from 'react';
import usePurchaseGoldModal from './usePurchaseGoldModal';
import styled from 'styled-components';
import MathUtils from 'services/utils/MathUtils';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { StoreGoldBundle, useGetStoreGoldBundlesQuery } from 'services/api/GraphqlTypes';
import { useEffect } from 'react';
import HtmlFormUtils from 'services/htmlForm/HtmlFormUtils';
import AppStore from 'services/stores/AppStore';
import Env from 'env';
import GoldAnalytics from 'services/analytics/GoldAnalytics';

const Img = styled.img`
    max-width: 100%;
`;

let tmpImgContainer: HTMLDivElement | null = null;

const PurchaseGoldModal: React.FC = () => {
    const form = useMemo(() => HtmlFormUtils.createMounted("POST", Env.API_URL + "/api/paypal/checkout"), []);
    const { visible, close: hide } = usePurchaseGoldModal();
    const { data, loading } = useGetStoreGoldBundlesQuery();
    const [redirecting, setRedirecting] = useState<number>(0);
    const bundles = data?.goldBundles || [];
    
    useEffect(() => {
        // Pre-load the images
        if (!loading && !tmpImgContainer) {
            const imgs = bundles.map(bundle => {
                const imgEl = document.createElement("img");
                imgEl.src = bundle.image;
                imgEl.style.display = "none";
                return imgEl;
            });
            tmpImgContainer = document.createElement("div");
            tmpImgContainer.append(...imgs);
            document.body.appendChild(tmpImgContainer);
            setTimeout(() => document.body.removeChild(tmpImgContainer!), 5000);
        }
    }, [loading]);

    useEffect(() => {
        if (bundles && visible) {
            GoldAnalytics.viewItems(bundles);
        }
    }, [bundles, visible]);

    const submit = (bundle: StoreGoldBundle): void => {
        form.getOrCreateInput("_token")
            .set("value", AppStore.user.token);
        form.getOrCreateInput("returnUrl")
            .set("value", `${Env.APP_URL}/store`);
        form.getOrCreateInput("goldBundleId")
            .set("value", bundle.id.toString());

        setRedirecting(bundle.id);

        GoldAnalytics.beginCheckout(bundle);

        form.render().submit();
    }

    return (
        <Modal
            visible={visible}
            title="Purchase Gold"
            onCancel={hide}
            footer={null}
            width={1000}
            style={{ top: 50 }}
        >
            <Row gutter={[48, 48]} wrap>
                {bundles.map(bundle => (
                    <Col key={bundle.id} span={12} sm={12} md={8} lg={6}>
                        <Card
                            cover={<Img alt="gold" src={bundle.image} />}
                            actions={[
                                <Button
                                    key="buy"
                                    type="primary"
                                    className="gold-button"
                                    style={{ minWidth: 100 }}
                                    loading={redirecting === bundle.id}
                                    disabled={redirecting > 0}
                                    onClick={() => submit(bundle)}
                                >
                                    ${MathUtils.roundToString(bundle.price)}
                                </Button>
                            ]}
                            bodyStyle={{
                                textAlign: "center",
                                padding: 16
                            }}
                        >
                            <Space direction="vertical">
                                <Title
                                    level={5}
                                    style={{ marginBottom: 0 }}
                                >{bundle.baseAmount} Gold</Title>

                                <Text type="warning">+ {bundle.bonusAmount} bonus Gold</Text>
                            </Space>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Modal>
    );
}

export default PurchaseGoldModal;