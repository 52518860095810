import { Button } from "antd";
import SpaceChar from "components/Helpers/SpaceChar";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import releaseDate from "releaseDate";

const PlayButton: React.FC = () => {
    return (
        <Countdown
            date={releaseDate}
            zeroPadDays={0}
            zeroPadTime={2}
            renderer={({ completed, days, formatted }) => {
                if (completed) {
                    return (
                        <Link to="/playnow">
                            <Button
                                type="primary"
                                size="large"
                                shape="round"
                                className="play-button"
                                style={{
                                    fontSize: 16,
                                    width: 235,
                                    height: 60,
                                    fontWeight: 700
                                }}
                            >
                                Play FREE
                            </Button>
                        </Link>
                    );
                } else {
                    return (
                        <Button
                            disabled
                            type="primary"
                            size="large"
                            shape="round"
                            style={{
                                fontSize: 28,
                                width: 235,
                                height: 60,
                                fontWeight: 700,
                                backgroundColor: "#663399",
                                borderColor: "#663399"
                            }}
                        >
                            <div>Play FREE in</div>
                            <div style={{ color: "#fff" }}>
                                {(days || 0) > 0 ? <>{days} Days<SpaceChar /></> : null}
                                {formatted.hours}:{formatted.minutes}:{formatted.seconds}
                            </div>
                        </Button>
                    );
                }
            }}
        />
    )
}

export default PlayButton;