import { Button, Card, Empty, List, Tooltip } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React from 'react';
import useShoppingCart, { useEmptyCart } from '../../../services/hooks/useShoppingCart';
import ShoppingCartItem from './ShoppingCartItem';
import { useCheckoutMutation, User } from '../../../services/api/GraphqlTypes';
import AppStore from '../../../services/stores/AppStore';
import Text from 'antd/lib/typography/Text';
import GoldIcon from 'components/Common/GoldIcon';
import { runInAction } from 'mobx';
import useUser from 'services/hooks/useUser';
import Analytics from 'services/analytics/Analytics';
import { v4 as uuid } from 'uuid';
import { isSoldOut } from 'services/helpers/storeItemHelpers';
import StoreAnalytics from 'services/analytics/StoreAnalytics';

interface Props {

}

const ShoppingCart: React.FC<Props> = () => {
    const [user] = useUser();
    const [cartItems, total, loading, refresh] = useShoppingCart();
    const [empty, { loading: emptying }] = useEmptyCart();
    const [checkout, { loading: checkingOut }] = useCheckoutMutation({
        onCompleted: r => {
            if (!r.checkout?.success) {
                r.checkout?.error && AppStore.notifications.showError("Oops!", r.checkout.error);
                return;
            }

            r.checkout?.items && AppStore.notifications.showSuccess("Purchase Complete!", (
                <>
                    You can claim the following items in-game by using the <Text code>::claim</Text> command.<br /><br />
                    <Text strong>{r.checkout.items.map(x => `${x.quantity} x ${x.name}`).join(", ")}</Text>
                </>
            ));

            r.checkout?.user && runInAction(() => AppStore.user.user = r.checkout?.user as User);
            refresh();
            
            StoreAnalytics.checkout(r.checkout?.items || []);
        }
    });
    
    const insuficientFunds = (user?.gold || 0) < total;
    const includesSoldOutItems = cartItems.some(x => isSoldOut(x));

    const checkoutBtn = (
        <Button
            type="primary"
            loading={checkingOut}
            disabled={checkingOut || emptying || !cartItems.length || insuficientFunds || includesSoldOutItems}
            onClick={() => checkout()}
        >Checkout</Button>
    );

    return (
        <Card
            className="shopping-cart"
            size="small"
            title="Cart"
            extra={(
                <Button
                    type="link"
                    loading={emptying}
                    disabled={emptying || !cartItems.length}
                    onClick={() => empty()}
                >empty</Button>
            )}
            actions={cartItems.length > 0 ? [
                <Text strong>Total: <GoldIcon suffix={total} marginLeft={8} /></Text>,
                includesSoldOutItems
                    ? <Tooltip title="Some items in your cart were sold out. Please remove them.">{checkoutBtn}</Tooltip>
                    : checkoutBtn
            ] : undefined}
        >
            <List
                loading={loading}
                itemLayout="vertical"
                dataSource={cartItems}
                renderItem={item => (
                    <ShoppingCartItem
                        item={item}
                        disabled={emptying || loading}
                    />
                )}
            >
                {cartItems.length === 0 && (
                    <Empty
                        description={!loading ? "Your cart is empty." : ""}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                )}
            </List>

            <Meta />
        </Card>
    );
}

export default ShoppingCart;