import React from "react";
import Layout from "antd/lib/layout/layout";
import { Route, Switch } from "react-router-dom";
import PlayNowDownload from "./playnow/PlayNowDownload";
import PlayNowRegister from "./playnow/PlayNowRegister";
import styled from "styled-components";

const LogoImg = styled.img`
    position: absolute;
    top: 16px;
    left: calc(50% - 125px);
    width: 250px;

    @media screen and (min-width: 991px) {
        top: 48px;
        left: 48px;
        width: 186px;
    }
`;

const PlayNow: React.FC = () => {
    return (
        <Layout
            style={{
                paddingTop: "64px",
                background: "transparent",
                userSelect: "none",
            }}
        >
            <a href="/">
                <LogoImg
                    src="/images/enchant-small.png"
                />
            </a>

            <Switch>
                <Route path="/playnow/" component={PlayNowRegister} exact />
                <Route path="/playnow/download" component={PlayNowDownload} />
                <Route path="/playnow/signup/download" component={() => <PlayNowDownload justRegistered />} />
                <Route path="/playnow/signup" component={PlayNowRegister} />
            </Switch>
        </Layout>
    )
}

export default PlayNow;
