import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const flashQueryConsumers = new Array<(query: URLSearchParams) => boolean>();

const flashQuery = _.debounce((history: ReturnType<typeof useHistory>) => {
    const query = new URLSearchParams(history.location.search);
    const isConsumed = flashQueryConsumers.map((consumer) => consumer(query))
        .some((result) => result);
    if (isConsumed) {
        history.replace(window.location.pathname);
    }
}, 50);

function useFlashQuery(consumer: (query: URLSearchParams) => boolean): void {
    const ref = useRef(consumer);
    ref.current = consumer;
    
    const history = useHistory();

    useEffect(() => {
        const index = flashQueryConsumers.length;
        flashQueryConsumers.push((query) => {
            return ref.current(query);
        });

        return () => {
            flashQueryConsumers.splice(index, 1);
        }
    }, []);

    useEffect(() => {
        history && flashQuery(history);
    }, [history]);
}

export const historyUtils = {
    useFlashQuery,
}
