// import TagManager from "react-gtm-module";
// import AppStore from "services/stores/AppStore";

// const GTM_ID = 'GTM-5Q7X86K';

export interface TransactionItem {
    name: string;
    sku?: string;
    category?: string;
    price?: number;
    currency?: string;
    quantity?: number;
}

export interface TransactionArgs {
    id: string;
    total?: number;
    affiliation?: string;
    tax?: number;
    shipping?: string;
    currency?: string;
    products?: TransactionItem[];
}

interface IEcommerceArgs {
    transaction_id?: string;
    tax?: number;
    shipping?: number;
    currency?: string;
    value?: number;
    items?: IEcommerceItem[];
    item_list_name?: string;
    item_list_id?: string;
}

interface IEcommerceItem {
    item_id: string;
    item_name: string;
    price?: string;
    quantity?: number;
    item_category?: string;
    item_variant?: string;
    item_brand?: string;
    index?: number;
    size?: string;
}

class Analytics {
    dataLayer: object[] = window.dataLayer;

    public setUser(userId?: string | number) {
        this.dataLayer.push({
            'user_id': userId?.toString()
        });
    }

    public event(eventName: string, dataLayer?: Dictionary<any>): void {
        this.dataLayer.push({
            'event': eventName,
            ...dataLayer
        });
    }

    public ecommerce(event: string, args: IEcommerceArgs): void {
        this.dataLayer.push({ ecommerce: null });
        this.dataLayer.push({
            event: event,
            ecommerce: args
        });
    }

    public viewItem(args: IEcommerceArgs): void {
        this.ecommerce("view_item", args);
    }
    public viewItemList(args: IEcommerceArgs): void {
        this.ecommerce("view_item_list", args);
    }
    public viewPromotion(args: IEcommerceArgs): void {
        this.ecommerce("view_promotion", args);
    }
    public addToCart(args: IEcommerceArgs): void {
        this.ecommerce("add_to_cart", args);
    }
    public removeFromCart(args: IEcommerceArgs): void {
        this.ecommerce("remove_from_cart", args);
    }
    public beginCheckout(args: IEcommerceArgs): void {
        this.ecommerce("begin_checkout", args);
    }
    public purchase(args: IEcommerceArgs): void {
        this.ecommerce("purchase", args);
    }
}

export default new Analytics();
