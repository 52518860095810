import Col from "antd/lib/col";
import { Divider, HeaderMega, InputLarge, ProgressStepsContainer, SceneForm, SubmitButton, TitleLarge, TitleMegaSub } from "./PlayNowCommon";
import dividerImage from "assets/images/playnow/divider.png";
import ProgressSteps from "components/Progress/ProgressSteps";
import Form from "antd/lib/form";
import { validationRules } from "services/helpers/validationRules";

type FormDataType = {
    name: string;
    password: string;
}

interface Props {
    onNextStep?: (data: FormDataType) => void;
    loading?: boolean;
    serverErrors?: Record<string, string>;
    style?: React.CSSProperties;
}

function PlayNowRegisterStep2({ onNextStep, loading, serverErrors, style = {} }: Props) {
    const [form] = Form.useForm<FormDataType>();
    
    return (
        <Col
            span={24}
            style={{
                marginBottom: 16,
                ...style,
            }}
        >
            <HeaderMega>
                <TitleLarge>Create a username and password</TitleLarge>
                <TitleMegaSub>This is what you'll use to login to the game</TitleMegaSub>
            </HeaderMega>

            <Divider>
                <img src={dividerImage} alt="Divider" />
            </Divider>

            <SceneForm<FormDataType>
                form={form}
                autoComplete="off"
                onFinish={onNextStep}
            >
                <Form.Item<FormDataType>
                    name="name"
                    rules={validationRules.name}
                    validateStatus={serverErrors?.name && "error"}
                    help={serverErrors?.name}
                >
                    <InputLarge
                        size="large"
                        placeholder="Username"
                    />
                </Form.Item>

                <Form.Item<FormDataType>
                    name="password"
                    rules={validationRules.password}
                    validateStatus={serverErrors?.password && "error"}
                    help={serverErrors?.password}
                >
                    <InputLarge
                        size="large"
                        placeholder="Password"
                        type="password"
                    />
                </Form.Item>

                <Form.Item<FormDataType>
                    name="confirm-password"
                    rules={[
                        { required: true, message: 'Please confirm your password' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The passwords you entered do not match'));
                            },
                        }),
                    ]}
                >
                    <InputLarge
                        size="large"
                        placeholder="Confirm Password"
                        type="password"
                    />
                </Form.Item>

                <SubmitButton loading={loading}>
                    Next
                </SubmitButton>
            </SceneForm>

            <ProgressStepsContainer>
                <ProgressSteps
                    currentStep={1}
                    steps={["Step 1", "Step 2"]}
                />
            </ProgressStepsContainer>
        </Col>
    );
}

export default PlayNowRegisterStep2;
