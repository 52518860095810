import Col from "antd/lib/col";
import { Divider, HeaderMega, InputMega, Link, LinkContainer, ProgressStepsContainer, SceneForm, SubmitButton, TitleMega, TitleMegaSub } from "./PlayNowCommon";
import dividerImage from "assets/images/playnow/divider.png";
import ProgressSteps from "components/Progress/ProgressSteps";
import Form from "antd/lib/form";
import { validationRules } from "services/helpers/validationRules";

type FormDataType = {
    email: string;
}

interface Props {
    onNextStep?: (data: FormDataType) => void;
    style?: React.CSSProperties;
}

function PlayNowRegisterStep1({ onNextStep, style = {} }: Props) {
    return (
        <Col
            span={24}
            style={{
                marginBottom: 16,
                ...style,
            }}
        >
            <HeaderMega>
                <TitleMega>Your journey starts here</TitleMega>
                <TitleMegaSub>First, let's get your email.</TitleMegaSub>
            </HeaderMega>

            <Divider>
                <img src={dividerImage} alt="Divider" />
            </Divider>

            <SceneForm<FormDataType>
                autoComplete="off"
                onFinish={onNextStep}
            >
                <Form.Item<FormDataType>
                    name="email"
                    rules={validationRules.email}
                >
                    <InputMega
                        size="large"
                        placeholder="Enter email"
                    />
                </Form.Item>

                <SubmitButton>
                    Start
                </SubmitButton>
            </SceneForm>

            <LinkContainer>
                ALREADY REGISTERED?&nbsp;
                <Link href="/playnow/download">
                    Click to Download
                </Link>
            </LinkContainer>

            <ProgressStepsContainer>
                <ProgressSteps
                    currentStep={0}
                    steps={["Step 1", "Step 2"]}
                />
            </ProgressStepsContainer>

        </Col>
    );
}

export default PlayNowRegisterStep1;
