import { Button, notification, Space } from "antd";
import React, { ReactNode } from "react";
import * as H from 'history';
import Env from "env";
import _ from "lodash";

export type GetFlashQueryProps = (query: URLSearchParams) => {
    key?: string;
    history: H.History;
    message: ReactNode;
    description?: ReactNode;
    type?: "success" | "error" | "info" | "warning";
    duration?: number;
    onClose?: () => void;
} | void;

export default class Notifications {
    public showSuccess(title: React.ReactNode, description: React.ReactNode, duration?: number) {
        const key = _.uniqueId("success-notification");
        notification.success({
            key,
            message: title,
            description: description,
            duration: duration || 0,
            btn: (
                <Button
                    onClick={() => notification.close(key)}
                >Close</Button>
            )
        })
    }

    public showError(title: React.ReactNode, description: React.ReactNode) {
        const key = _.uniqueId("error-notification");
        notification.error({
            key,
            message: title,
            description: description,
            type: "error",
            duration: 0,
            btn: (
                <Space>
                    <Button
                        type="link"
                        onClick={() => window.open(Env.DISCORD_SUPPORT_LINK, "_blank")}
                    >Support</Button>

                    <Button
                        onClick={() => notification.close(key)}
                    >Close</Button>
                </Space>
            )
        })
    }
}
