import { Rule } from "antd/lib/form";

export const validationRules = {
    email: [
        {
            required: true,
            type: "email",
            message: 'Please enter a valid email address',
        }
    ] as Rule[],
    name: [
        {
            required: true,
            type: "string",
            min: 3,
            max: 12,
            message: 'You must enter a username between 3 and 12 characters',
        },
        {
            pattern: /^[A-Za-z][A-Za-z0-9]*$/,
            message: 'Your username must start with a letter and can only contain letters and numbers',
        }
    ] as Rule[],
    password: [
        {
            required: true,
            type: "string",
            min: 6,
            max: 24,
            message: 'You must enter a password between 6 and 24 characters',
        },
        {
            pattern: /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]+$/,
            message: 'Your password can only contain letters, numbers, and special characters',
        },
    ] as Rule[],
} as const;
