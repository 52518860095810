import type { ApolloError } from "@apollo/client";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import { useEffect, useMemo, useState } from "react";
import type { PincodeSent } from "services/api/GraphqlTypes";
import TimeoutUtils from "services/utils/TimeoutUtils";

interface Props {
    onPincodeChange?: (value: string) => void;
    onSendPincode: () => Promise<any>;
    response: {
        data?: PincodeSent,
        loading: boolean,
        error?: ApolloError,
    };
    disabled?: boolean;
}

function PincodeForm({ onPincodeChange, onSendPincode, response, disabled }: Props) {
    const [pincodeTimer, setPincodeTimer] = useState(0);
    const { setInterval, clearInterval } = TimeoutUtils.useManaged();

    const { data, loading, error } = response;

    useEffect(() => {
        if (data?.timer) {
            setPincodeTimer(data.timer);
            const interval = setInterval(() => {
                setPincodeTimer(t => {
                    if (t <= 1) {
                        clearInterval(interval);
                    }
                    return t - 1;
                });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [data]);

    return (
            <div>
                <Form.Item
                    help={data ? "Enter the PIN sent to your email" : undefined}
                    style={{ display: 'inline-block', width: 'calc(50% - 6px)' }}
                >
                    <Button
                        type="primary"
                        style={{ width: "100%" }}
                        loading={loading}
                        disabled={pincodeTimer > 0 || disabled}
                        onClick={onSendPincode}
                    >
                        {pincodeTimer === 0 ?
                            "Request PIN" :
                            `Resend PIN (${Math.floor(pincodeTimer / 60)}:${(pincodeTimer % 60).toString().padStart(2, "0")})`
                        }
                    </Button>
                </Form.Item>

                <span style={{ paddingLeft: "12px" }} />

                <Form.Item
                    name="pincode"
                    rules={[{
                        required: true,
                        message: 'Please enter the PIN sent to your email'
                    }]}
                    style={{ display: 'inline-block', width: 'calc(50% - 6px)' }}
                >
                    <Input
                        name="pincode"
                        placeholder="_ _ _ _ _ _"
                        disabled={loading}
                        onChange={e => onPincodeChange?.(e.target.value)}
                    />
                </Form.Item>
            </div>
    );
}

export default PincodeForm;
