import { Alert, Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import MailOutlined from '@ant-design/icons/MailOutlined';
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { User, useChangeEmailMutation, usePincodeVerifyEmailMutation } from "services/api/GraphqlTypes";
import PincodeForm from "components/Forms/PincodeForm";
import useUser from "services/hooks/useUser";
import { validationRules } from "services/helpers/validationRules";

interface Props {
    verificationToken?: string;
    onSuccess?: () => void;
}

function ChangeEmailForm({ verificationToken, onSuccess }: Props) {
    const [user, _, { updateUser }] = useUser();
    
    const [form] = useForm();
    const [email, setEmail] = useState(user?.email || "");
    const [isValidEmail, setIsValidEmail] = useState(false);
    useLayoutEffect(() => setEmail(user?.email || ""), [user?.email]);

    const [pincode, setPincode] = useState("");

    const [changeEmail, { loading, error, data }] = useChangeEmailMutation({
        variables: {
            email,
            pincode,
            verificationToken,
        },
        onCompleted: data => {
            data.changeEmail && updateUser({ ...data.changeEmail } as User);
        },
    });

    const [sendPincode, { data: pincodeSent, loading: pincodeLoading, error: pincodeError }] = usePincodeVerifyEmailMutation({
        variables: { email },
    });
    const pincodeResponse = useMemo(() => ({
        data: pincodeSent?.pincodeVerifyEmail,
        loading: pincodeLoading,
        error: pincodeError
    }), [pincodeSent, pincodeLoading, pincodeError]);

    useEffect(() => {
        form.validateFields(["email"])
            .then(() => setIsValidEmail(true))
            .catch(() => setIsValidEmail(false));
    }, [email, form]);
    
    return (
        <Form
            form={form}
            name="change-email"
            onFinish={() => {
                changeEmail()
                    .then(r => r.data?.changeEmail?.id && onSuccess?.());
            }}
        >
            {error && (
                <Form.Item>
                    <Alert message={error.message} type="error" />
                </Form.Item>
            )}

            <Form.Item
                name="email"
                rules={validationRules.email}
            >
                <Input
                    placeholder="Email"
                    autoComplete="email"
                    prefix={<MailOutlined />}
                    disabled={loading}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
            </Form.Item>

            <PincodeForm
                onPincodeChange={setPincode}
                onSendPincode={() => sendPincode()}
                response={pincodeResponse}
                disabled={!isValidEmail}
            />

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    loading={loading}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

export default ChangeEmailForm;
