export class Queue<T> {
    private readonly items: T[];

    constructor(items: T[] = []) {
        this.items = items;
    }

    // Get the size of the queue
    public get length() {
        return this.items.length;
    }

    // Enqueue: Add an element to the end of the queue
    public enqueue(element: T) {
        this.items.push(element);
    }

    // Dequeue: Remove and return the first element from the queue
    public dequeue(): T | undefined {
        if (this.isEmpty()) {
            return undefined;
        }
        return this.items.shift()!;
    }

    // Peek: Get the first element in the queue without removing it
    public peek(): T | undefined {
        if (this.isEmpty()) {
            return undefined;
        }
        return this.items[0];
    }

    // Check if the queue is empty
    public isEmpty() {
        return this.items.length === 0;
    }

    // Clear the queue
    public clear() {
        this.items.length = 0;
    }
}
