import { Button } from 'antd';
import PurchaseGoldModal from 'components/Store/PurchaseGoldModal/PurchaseGoldModal';
import usePurchaseGoldModal from 'components/Store/PurchaseGoldModal/usePurchaseGoldModal';
import React from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Queue } from 'services/utils/Queue';
import TimeoutUtils from 'services/utils/TimeoutUtils';
import { v4 as uuid } from 'uuid';

interface Props {
    children?: React.ReactNode;
}

interface Star {
    uuid: string;
    style: React.CSSProperties;
    speed: number;
    direction: { x: number, y: number };
    createdAt: number;
}

class AddStarCommand {
    constructor(public readonly star: Star) { }
}

class RemoveStarCommand {
    constructor(public readonly uuid: string) { }
}

type Command = AddStarCommand | RemoveStarCommand;

function PurchaseGoldButton({ children }: Props) {
    const { open: openPurchaseGoldModal } = usePurchaseGoldModal();
    const { setTimeout, setInterval, clearInterval } = TimeoutUtils.useManaged();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const starRefs = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});
    const timeScaleRef = useRef(1);
    const commandQueueRef = useRef<Queue<Command>>(new Queue());

    const [stars, setStars] = useState<Star[]>([]);
    useLayoutEffect(() => {
        console.log("Stars updated", stars);
    }, [stars]);

    const firstRef = useRef(true);

    useEffect(() => {
        function createStar() {
            commandQueueRef.current
                .enqueue(
                    new AddStarCommand({
                        uuid: firstRef.current ? "123" : uuid(),
                        style: generateCenterStarStyles(),
                        speed: Math.random() * 1 + 1,
                        direction: {
                            x: Math.random() * 2 - 1,
                            y: Math.random() * 2 - 1,
                        },
                        createdAt: Date.now(),
                    })
                );
            // setStars(stars => {
            //     return [...stars, {
            //         uuid: firstRef.current ? "123" : uuid(),
            //         style: generateCenterStarStyles(),
            //         speed: Math.random() * 1 + 1,
            //         direction: {
            //             x: Math.random() * 2 - 1,
            //             y: Math.random() * 2 - 1,
            //         },
            //         createdAt: Date.now(),
            //     }];
            // });
            firstRef.current = false;
        }

        function speedUpForDuration(duration: number) {
            const original = timeScaleRef.current;
            timeScaleRef.current = 5;
            // Gradually slow down over time
            const interval = setInterval(() => {
                timeScaleRef.current = Math.max(1, timeScaleRef.current - 0.1);
                if (timeScaleRef.current <= original) {
                    timeScaleRef.current = original;
                    clearInterval(interval);
                }
            }, 50);
        }

        function createStarCycle() {
            createStar();
            // const interval = setInterval(() => {
            //     new Array(Math.ceil(timeScaleRef.current)).fill(0).forEach(() => createStar());
            // }, 50);
            // setTimeout(() => {
            //     clearInterval(interval);
            //     buttonRef.current?.classList.remove("bounce");
            //     setTimeout(() => createStarCycle(), 4500);
            // }, 500);
            // setTimeout(() => {
            //     buttonRef.current?.classList.add("bounce");
            //     speedUpForDuration(1000);
            // }, 150);
        }

        createStarCycle();
    }, []);

    // Movement animation of stars
    useEffect(() => {
        const interval = setInterval(() => {
            setStars(stars => {
                let newStars = [...stars];
                // Process queue commands
                const command = commandQueueRef.current.dequeue();
                if (command instanceof RemoveStarCommand) {
                    const indexToRemove = stars.findIndex(star => star.uuid === command.uuid);
                    if (indexToRemove > -1) {
                        newStars.splice(indexToRemove, 1);
                    }
                }

                newStars = newStars.map(star => {
                    const style = { ...star.style };
                    const speed = star.speed * timeScaleRef.current;
                    style.top = parseFloat(style.top as string) + (speed * star.direction.y) + '%';
                    style.left = parseFloat(style.left as string) + (speed * star.direction.x) + '%';
                    // Increate height over time
                    style.height = parseFloat(style.height as string) + (0.06 * timeScaleRef.current) + 'px';
                    style.width = style.height;
                    return {
                        ...star,
                        style,
                    };
                });

                if (command instanceof AddStarCommand) {
                    newStars = [...newStars, command.star];
                }

                return newStars;
            });
        }, 50);
        return () => clearInterval(interval);
    }, [setStars]);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setStars(stars => {
    //             const newStars = [...stars];
    //             // Filter out of view stars
    //             if (buttonRef.current) {
    //                 const buttonRect = buttonRef.current.getBoundingClientRect();
    //                 const refsClone = { ...starRefs.current };
    //                 Object.entries(refsClone)
    //                     .filter(([, ref]) => ref?.current)
    //                     .forEach(([uuid, ref]) => {
    //                         const starRect = ref.current!.getBoundingClientRect();

    //                         // Check if the star is completely outside the vertical boundaries of the button
    //                         const isVerticallyOutside = starRect.bottom < buttonRect.top || starRect.top > buttonRect.bottom;

    //                         // Check if the star is completely outside the horizontal boundaries of the button
    //                         const isHorizontallyOutside = starRect.right < buttonRect.left || starRect.left > buttonRect.right;

    //                         if (isVerticallyOutside || isHorizontallyOutside) {
    //                             const indexToRemove = newStars.findIndex(star => star.uuid === uuid);
    //                             if (indexToRemove > -1) {
    //                                 console.log("Removing star", uuid, starRefs.current[uuid]);
    //                                 newStars.splice(indexToRemove, 1);
    //                             }
    //                             delete starRefs.current[uuid];
    //                         }
    //                     });
    //             }
    //             const result = newStars.length !== stars.length ? newStars : stars;
    //             console.log("Stars updated", result);
    //             return result;
    //         });
    //     }, 10000);
    // }, []);

    useEffect(() => {
        console.log("Stars updated", stars);
        // Filter out of view stars
        if (buttonRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect();
            const refsClone = { ...starRefs.current };
            Object.entries(refsClone)
                .filter(([, ref]) => ref?.current)
                .forEach(([uuid, ref]) => {
                    const starRect = ref.current!.getBoundingClientRect();

                    // Check if the star is completely outside the vertical boundaries of the button
                    const isVerticallyOutside = starRect.bottom < buttonRect.top || starRect.top > buttonRect.bottom;

                    // Check if the star is completely outside the horizontal boundaries of the button
                    const isHorizontallyOutside = starRect.right < buttonRect.left || starRect.left > buttonRect.right;

                    if (isVerticallyOutside || isHorizontallyOutside) {
                        commandQueueRef.current.enqueue(new RemoveStarCommand(uuid));
                    }
                });
        }
        // setStars(stars => {
        //     const newStars = [...stars];
        //     // Filter out of view stars
        //     if (buttonRef.current) {
        //         const buttonRect = buttonRef.current.getBoundingClientRect();
        //         const refsClone = { ...starRefs.current };
        //         Object.entries(refsClone)
        //             .filter(([, ref]) => ref?.current)
        //             .forEach(([uuid, ref]) => {
        //                 const starRect = ref.current!.getBoundingClientRect();

        //                 // Check if the star is completely outside the vertical boundaries of the button
        //                 const isVerticallyOutside = starRect.bottom < buttonRect.top || starRect.top > buttonRect.bottom;

        //                 // Check if the star is completely outside the horizontal boundaries of the button
        //                 const isHorizontallyOutside = starRect.right < buttonRect.left || starRect.left > buttonRect.right;

        //                 if (isVerticallyOutside || isHorizontallyOutside) {
        //                     const indexToRemove = newStars.findIndex(star => star.uuid === uuid);
        //                     if (indexToRemove > -1) {
        //                         console.log("Removing star", uuid, starRefs.current[uuid]);
        //                         newStars.splice(indexToRemove, 1);
        //                     }
        //                     delete starRefs.current[uuid];
        //                 }
        //             });
        //     }
        //     return newStars.length !== stars.length ? newStars : stars;
        // });
    }, [stars]);

    return (
        <>
            <div className="purchase-gold-button-wrapper">
                <Button
                    className="purchase-gold-button"
                    shape="round"
                    ref={buttonRef}
                    onClick={openPurchaseGoldModal}
                >
                    <div className="stars">
                        {stars.map((star) => {
                            starRefs.current[star.uuid] = starRefs.current[star.uuid] ?? React.createRef<HTMLDivElement>();
                            return (
                                <div
                                    key={star.uuid}
                                    id={star.uuid}
                                    className="star"
                                    style={star.style}
                                    // ref={ref => starRefs.current[star.uuid].current = ref as HTMLDivElement}
                                    ref={starRefs.current[star.uuid]}
                                />
                            )
                        })}
                    </div>
                    {children ?? "Purchase Gold"}
                </Button>
            </div>
            <PurchaseGoldModal />
        </>
    )
}

function generateCenterStarStyles() {
    const style: React.CSSProperties = {};
    const color = 'rgba(255,255,200,0.5)';
    style.backgroundColor = color;
    style.borderRadius = '50%';
    style.position = 'absolute';

    style.top = '50%';
    style.left = '50%';
    style.height = 0;
    style.width = 0;

    var glow = Math.random() * 2;
    style.boxShadow = `0 0 ${glow}px ${glow / 2}px ${color}`
    style.animationDuration = Math.random() * 3 + 1 + 's';

    return style;
}

function generateGlowingStarStyles() {
    const style: React.CSSProperties = {};
    const color = 'rgba(255,255,200,0.5)';
    style.backgroundColor = color;
    style.borderRadius = '50%';
    style.position = 'absolute';
    style.top = Math.random() * 100 + '%';
    style.left = Math.random() * 100 + '%';
    style.height = Math.random() * 5 + 'px';
    style.width = style.height;

    var glow = Math.random() * 2;
    style.boxShadow = `0 0 ${glow}px ${glow / 2}px ${color}`
    style.animationDuration = Math.random() * 3 + 1 + 's';

    return style;
}

export default PurchaseGoldButton;
