import { makeAutoObservable } from "mobx";
import { useLayoutEffect, useMemo, useState } from "react";
import PlayNowRegisterStep1 from "./PlayNowRegisterStep1";
import Row from "antd/lib/grid/row";
import PlayNowRegisterStep2 from "./PlayNowRegisterStep2";
import { BackgroundBlur } from "./PlayNowCommon";
import Env from "env";
import TimeoutUtils from "services/utils/TimeoutUtils";
import { useHistory } from "react-router-dom";
import { LoginResponse, useRegisterMutation } from "services/api/GraphqlTypes";
import AppStore from "services/stores/AppStore";

export class RegistrationStore {
    public email: string = "";
    public name: string = "";
    public password: string = "";

    constructor() {
        makeAutoObservable(this);
    }
}

function PlayNowRegister() {
    const store = useMemo(() => new RegistrationStore(), []);
    const [register, { loading, error }] = useRegisterMutation({
        onError: (error) => {
            console.error("Register error", error);
        },
        onCompleted: data => {
            console.log("Register result", data);
            data.register && AppStore.user.onLogin({ ...data.register } as LoginResponse);
            history.push("/playnow/signup/download");
        },
    });
    const [stepIndex, setStepIndex] = useState(0);
    const { setTimeout } = TimeoutUtils.useManaged();
    const history = useHistory();
    
    const [styleStep1, setStyleStep1] = useState<React.CSSProperties>({
        opacity: 1,
        transition: "opacity 0.3s ease-in-out",
    });
    const [styleStep2, setStyleStep2] = useState<React.CSSProperties>({
        opacity: 0,
        transition: "opacity 0.3s ease-in-out",
        display: "none",
    });

    const serverErrors = useMemo(() => {
        const errorMap = error?.graphQLErrors.reduce<Record<string, string>>((pv, cv) => {
            if (cv.extensions?.category === "validation") {
                const validation = cv.extensions.validation as Record<string, string[]>;
                return {
                    ...pv,
                    ...Object.keys(validation).reduce<Record<string, string>>((errorMap, key) => {
                        return {
                            ...errorMap,
                            [key]: pv[key] ? `${pv[key]}, ${validation[key].join(", ")}` : validation[key].join(", "),
                        }
                    }, {})
                }
            } else if (cv.extensions?.category === "default") {
                return {
                    ...pv,
                    internal: cv.message,
                };
            } else if (cv.extensions?.category === "internal") {
                return {
                    ...pv,
                    internal: (cv as any).debugMessage || cv.message || "Internal server error",
                };
            }
            return pv;
        }, {});
        console.log("Error map", errorMap);
        return errorMap;
    }, [error]);

    useLayoutEffect(() => {
        if (serverErrors?.internal) {
            AppStore.notifications.showError("Error", serverErrors.internal);
        }
    }, [serverErrors]);

    useLayoutEffect(() => {
        if (stepIndex === 1) {
            setStyleStep1(style => ({
                ...style,
                opacity: 0,
            }));

            setTimeout(() => {
                setStyleStep1(style => ({
                    ...style,
                    display: "none",
                }));
                setStyleStep2(style => ({
                    ...style,
                    display: "block",
                    opacity: 1,
                }));
            }, 300);
        }
    }, [stepIndex]);

    return (
        <Row
            align="middle"
            justify="center"
            style={{
                textAlign: "center",
                backgroundColor: "rgba(0,0,0,0.7)",
                marginTop: -64,
                paddingTop: 64,
                flex: 1,
            }}
        >
            <PlayNowRegisterStep1
                onNextStep={(data) => {
                    store.email = data.email;
                    setStepIndex(i => i + 1);
                }}
                style={styleStep1}
            />

            <PlayNowRegisterStep2
                serverErrors={serverErrors}
                onNextStep={(data) => {
                    store.name = data.name;
                    store.password = data.password;

                    register({
                        variables: {
                            email: store.email,
                            name: store.name,
                            password: store.password,
                        }
                    })
                }}
                loading={loading}
                style={styleStep2}
            />

            <BackgroundBlur>
                <img src={Env.BACKGROUND_IMAGE} />
            </BackgroundBlur>
        </Row>
    );
}

export default PlayNowRegister;
