import { useRef, useState } from "react";
import TimeoutUtils from "services/utils/TimeoutUtils";

function useFlashValue<T>(defaultValue: T, duration: number = 5000) {
    const [value, setValue] = useState(defaultValue);
    const { setTimeout, clearTimeout } = TimeoutUtils.useManaged();
    const timeoutRef = useRef<number>();

    const flashValue = (newValue: T) => {
        setValue(newValue);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setValue(defaultValue);
        }, duration);
    };

    return [value, flashValue] as const;
}

export default useFlashValue;
