import { Typography } from "antd";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import PincodeForm from "components/Forms/PincodeForm";
import React, { useMemo, useState } from "react";
import { PincodeSent, usePincodeChangeEmailMutation, usePincodeChangePasswordMutation, usePincodeCreateVerificationTokenMutation } from "services/api/GraphqlTypes";

const Mutations = {
    "change-email": {
        useMutation: usePincodeChangeEmailMutation,
        getPincodeSent: (data: ReturnType<typeof usePincodeChangeEmailMutation>[1]["data"]): PincodeSent | undefined => data?.pincodeChangeEmail,
    },
    "change-password": {
        useMutation: usePincodeChangePasswordMutation,
        getPincodeSent: (data: ReturnType<typeof usePincodeChangePasswordMutation>[1]["data"]): PincodeSent | undefined => data?.pincodeChangePassword,
    },
}

interface Props {
    children?: React.ReactNode;
    scope: keyof typeof Mutations;
}

type PincodeGuardContext = { verificationToken: string };
const Context = React.createContext<PincodeGuardContext>({ verificationToken: "" });

function PincodeGuard({ children, scope }: Props) {
    const helpers = Mutations[scope];

    const [sendPincode, pincodeResponse] = helpers.useMutation();
    const response = useMemo(() => ({
        ...pincodeResponse,
        data: helpers.getPincodeSent(pincodeResponse.data),
    }), [pincodeResponse]);

    const [createVerificationToken, { loading }] = usePincodeCreateVerificationTokenMutation();

    const [contextValue, setContextValue] = useState<PincodeGuardContext>({ verificationToken: "" });

    if (contextValue.verificationToken) {
        return (
            <Context.Provider value={contextValue}>
                {children}
            </Context.Provider>
        )
    }

    return (
        <Form<{ pincode: string }>
            onFinish={(data) => {
                createVerificationToken({
                    variables: {
                        pincode: data.pincode,
                        scope: helpers.getPincodeSent(pincodeResponse.data)?.scope || scope,
                    }
                })
                .then(r => r.data?.pincodeCreateVerificationToken?.token)
                .then(token => setContextValue({ verificationToken: token || "" }));
            }}
        >
            <Typography.Title level={5}>This action requires authentication</Typography.Title>

            <PincodeForm
                onSendPincode={() => sendPincode()}
                response={response}
                disabled={loading || pincodeResponse.loading}
            />

            <Form.Item style={{ marginTop: 12 }}>
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    loading={loading}
                >
                    Authorize
                </Button>
            </Form.Item>
        </Form>
    );
}

PincodeGuard.useContext = function useContext() {
    return React.useContext(Context);
}

export default PincodeGuard;
