import { historyUtils } from "services/historyUtils/HistoryUtils";
import Notifications from "../notifications/Notifications";
import { ShoppingCartStore } from "./ShoppingCartStore";
import StoreItemsStore from "./StoreItemsStore/StoreItemsStore";
import UserStore from "./UserStore";

class AppStore {
    public readonly user = new UserStore();
    public readonly shoppingCart = lazy(() => new ShoppingCartStore(this.user));
    public readonly notifications = new Notifications();
    public readonly createStoreItemsStore = () => new StoreItemsStore();
    public readonly history = historyUtils;
}

function lazy<T>(factory:  () => T): T {
    // @ts-expect-error
    return { isLazy: true, factory };
}

const store = new Proxy(new AppStore(), {
    get(target, name) {
        // @ts-expect-error
        const prop = target[name];

        if (prop === undefined) return undefined;
        if (prop === null) return null;

        if (typeof prop === 'object' && typeof prop.factory === 'function' && prop.isLazy === true ) {
            // @ts-expect-error
            target[name] = prop.factory();
        }

        // @ts-expect-error
        return target[name];
    }
});

export default store;

(window as any).store = store;