import { makeObservable, observable, runInAction } from "mobx";
import { StoreItem } from "services/api/GraphqlTypes";
import StoreItemsSubscriptionStore from "./StoreItemsSubscriptionStore";

export default class ReactiveStoreItemsStore {
    public readonly storeItems: StoreItem[];
    
    private readonly subscription: ReturnType<typeof StoreItemsSubscriptionStore.stream.subscribe>;

    constructor(storeItems: StoreItem[]) {
        this.storeItems = storeItems;
        
        makeObservable(this, {
            storeItems: observable
        });

        const ids = storeItems.map(x => x.id);

        let subscription: ReturnType<typeof StoreItemsSubscriptionStore.stream.subscribe>;
        
        this.subscription = StoreItemsSubscriptionStore.stream
            .filter(x => {
                return StoreItemsSubscriptionStore.validate(x) && x.data!.storeItemStockUpdate!.some(d => ids.contains(d.id));
            })
            .subscribe(message => {
                // Store was destroyed, unsubscribe
                if (!this) {
                    if (subscription.closed) {
                        console.error("Received a subscription callback even though the subscription was closed!");
                        return;
                    }
                    subscription.unsubscribe();
                    return;
                }

                runInAction(() => {
                    (message.data?.storeItemStockUpdate || []).forEach(u => {
                        const item = this.storeItems.find(x => x.id === u.id);
                        if (!item) {
                            return;
                        }
                        item.stock = typeof u.stock === "number" && u.stock > -1 ? u.stock : item.stock;
                    });
                });
            });

        subscription = this.subscription;
    }

    public dispose(): void {
        if (this.subscription.closed) {
            return;
        }
        this.subscription.unsubscribe();
    }
}
