import React, { useCallback, useEffect } from "react";

const TimeoutUtils = {
    useManaged: () => {
        const timeoutsRef = React.useRef<number[]>([]);
        const intervalsRef = React.useRef<number[]>([]);

        const setTimeout = useCallback((callback: () => void, timeout: number) => {
            const id = window.setTimeout(callback, timeout);
            timeoutsRef.current.push(id);
            return id;
        }, []);
        const setInterval = useCallback((callback: () => void, timeout: number) => {
            const id = window.setInterval(callback, timeout);
            intervalsRef.current.push(id);
            return id;
        }, []);
        const clearTimeout = useCallback((id: number) => {
            window.clearTimeout(id);
            timeoutsRef.current = timeoutsRef.current.filter((t) => t !== id);
        }, []);
        const clearInterval = useCallback((id: number) => {
            window.clearInterval(id);
            intervalsRef.current = intervalsRef.current.filter((t) => t !== id);
        }, []);

        useEffect(() => {
            return () => {
                timeoutsRef.current.forEach((id) => window.clearTimeout(id));
                intervalsRef.current.forEach((id) => window.clearInterval(id));
            };
        }, []);

        return { setTimeout, setInterval, clearTimeout, clearInterval };
    },
}

export default TimeoutUtils;
