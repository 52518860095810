import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages';
import Store from './pages/store';
import Vote from './pages/vote';
import Highscores from 'pages/highscores';
import FlashQueryAnalytics from 'components/Helpers/FlashQueryAnalytics';
import FlashQueryNotification from 'components/Helpers/FlashQueryNotification';
import PlayNow from 'pages/playnow';
import GraphQL from 'services/api/GraphQL';
import { ApolloProvider } from "@apollo/client";
import { layoutMain } from 'layouts/LayoutMain';
import Account from 'pages/account';

const App: React.FC = () => {
    return (
        <BrowserRouter>

                <ApolloProvider
                    client={GraphQL.client}
                >
                    <FlashQueryAnalytics />
                    <FlashQueryNotification />

                    <Switch>
                        <Route path="/store" component={layoutMain(Store)} />
                        <Route path="/vote" component={layoutMain(Vote)} />
                        <Route path="/highscores" component={layoutMain(Highscores)} />
                        <Route path="/account" component={layoutMain(Account)} />
                        <Route path="/" component={layoutMain(Home)} exact />
                        {/* No Layout */}
                        <Route path="/playnow" component={PlayNow} />
                    </Switch>
                </ApolloProvider>

        </BrowserRouter>
    );
}

export default App;
