import { MutationFunctionOptions, MutationTuple } from "@apollo/client";
import { useObserver } from "mobx-react-lite";
import { useMemo } from "react";
import { CartItem, Exact, Maybe, StoreItem, UpdateCartItemMutation, UpdateCartItemMutationVariables, useEmptyCartMutation, useUpdateCartItemMutation } from "../api/GraphqlTypes";
import AppStore from "../stores/AppStore";
import StoreAnalytics from "services/analytics/StoreAnalytics";

type Result = [ CartItem[], number, boolean, () => Promise<void> ];

const useShoppingCart: Func<Result> = () => {
    const shoppingCartStore = useMemo(() => AppStore.shoppingCart, []);
    const loading = useObserver(() => shoppingCartStore.loading);
    const cartItems = useObserver(() => shoppingCartStore.cartItems);
    const total = useObserver(() => shoppingCartStore.total);

    const refresh = () => shoppingCartStore.refresh();

    return [cartItems, total, loading, refresh];
}

type AddToCartVariables = Exact<{
    storeItemId: number;
    quantity?: Maybe<number> | undefined;
}> | undefined

export const useAddToCart: FuncOf<StoreItem, MutationTuple<UpdateCartItemMutation, UpdateCartItemMutationVariables>> = (item: StoreItem) => {
    const [update, data] = useUpdateCartItemMutation({
        onCompleted: r => {
            if (!r?.updateCartItem) return;
            
            AppStore.shoppingCart.onItemUpdate({
                storeItem: item,
                quantity: r.updateCartItem.quantity
            });

            StoreAnalytics.addToCart(AppStore.shoppingCart.total, {
                storeItem: item,
                quantity: r.updateCartItem.quantity
            });
        }
    });

    const wrapper = (options?: MutationFunctionOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>) => {
        const cartItem = AppStore.shoppingCart.find(item.id);
        return update({
            variables: {
                storeItemId: item.id || 0,
                quantity: (cartItem?.quantity || 0) + 1
            },
            ...options
        });
    }

    return [wrapper, data];
}

export const useUpdateCartItem = (variables?: AddToCartVariables) => {
    const mutationResult = useUpdateCartItemMutation({
        variables,
        onCompleted: r => {
            if (!r?.updateCartItem) return;
            
            AppStore.shoppingCart
                .onItemUpdate(r.updateCartItem);
            
            const item = AppStore.shoppingCart.find(r.updateCartItem.storeItemId);
            if (!item) return;

            if (r.updateCartItem.quantity === 0) {
                StoreAnalytics.removeFromCart(AppStore.shoppingCart.total, item);
            } else {
                StoreAnalytics.addToCart(AppStore.shoppingCart.total, item);
            }
        }
    });
    return mutationResult;
}

export const useEmptyCart = () => {
    const mutation = useEmptyCartMutation({
        onCompleted: r => {
            if (!r?.emptyCart) return;
            StoreAnalytics.emptyCart(AppStore.shoppingCart.cartItems);
            AppStore.shoppingCart.cartItems = [];
        }
    });
    return mutation;
}

export default useShoppingCart;
