import { Alert, Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import LockOutlined from '@ant-design/icons/LockOutlined';
import { useEffect, useState } from "react";
import { User, useChangePasswordMutation } from "services/api/GraphqlTypes";
import useUser from "services/hooks/useUser";
import { validationRules } from "services/helpers/validationRules";

interface Props {
    verificationToken?: string;
    onSuccess?: () => void;
}

function ChangePasswordForm({ verificationToken, onSuccess }: Props) {
    const [user, _, { updateUser }] = useUser();

    const [form] = useForm();
    const [password, setPassword] = useState("");
    const [isValidPassword, setIsValidPassword] = useState(false);

    const [changePassword, { loading, error, data }] = useChangePasswordMutation({
        variables: {
            password,
            verificationToken,
        },
        onCompleted: data => {
            data.changePassword && updateUser({ ...data.changePassword } as User);
        },
    });

    useEffect(() => {
        form.validateFields(["password"])
            .then(() => setIsValidPassword(true))
            .catch(() => setIsValidPassword(false));
    }, [password, form]);

    return (
        <Form
            form={form}
            name="change-password"
            onFinish={() => {
                changePassword()
                    .then(r => r.data?.changePassword?.id && onSuccess?.());
            }}
        >
            {error && (
                <Form.Item>
                    <Alert message={error.message} type="error" />
                </Form.Item>
            )}

            <Form.Item
                name="password"
                rules={validationRules.password}
            >
                <Input
                    placeholder="Password"
                    type="password"
                    prefix={<LockOutlined />}
                    disabled={loading}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
            </Form.Item>

            <Form.Item
                name="confirm-password"
                rules={[
                    { required: true, message: 'Please confirm your password' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The passwords you entered do not match'));
                        },
                    }),
                ]}
            >
                <Input
                    placeholder="Confirm Password"
                    type="password"
                />
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    loading={loading}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

export default ChangePasswordForm;
