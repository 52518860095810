import { GetStoreGoldBundlesQuery } from "services/api/GraphqlTypes";
import Analytics from "./Analytics";

class GoldAnalytics {
    public viewItems(items: GetStoreGoldBundlesQuery["goldBundles"]): void {
        Analytics.viewItemList({
            currency: "USD",
            item_list_name: "Purchase Gold Modal",
            items: items.map((item, index) => ({
                item_id: item.id.toString(),
                item_name: `Gold Bundle (${item.totalAmount}g)`,
                price: item.price.toString(),
                item_category: "Gold",
                index: index,
                quantity: 1,
            })),
        });
    }

    public beginCheckout(item: GetStoreGoldBundlesQuery["goldBundles"][0]): void {
        Analytics.beginCheckout({
            currency: "USD",
            value: item.price,
            items: [
                {
                    item_id: item.id.toString(),
                    item_name: `Gold Bundle (${item.totalAmount}g)`,
                    price: item.price.toString(),
                    item_category: "Gold",
                    quantity: 1,
                },
            ],
        });
    }

    public purchase(item: GetStoreGoldBundlesQuery["goldBundles"][0]): void {
        Analytics.purchase({
            currency: "USD",
            value: item.price,
            items: [
                {
                    item_id: item.id.toString(),
                    item_name: `Gold Bundle (${item.totalAmount}g)`,
                    price: item.price.toString(),
                    item_category: "Gold",
                    quantity: 1,
                },
            ],
        });
    }
}

export default new GoldAnalytics();
