import AppContent from "AppContent";
import Layout from "antd/lib/layout/layout";
import AppFooter from "components/AppFooter";
import AppHeader from "components/AppHeader";
import Env from "env";

interface Props {
    children: React.ReactNode;
}

function LayoutMain({ children }: Props) {
    const search = new URLSearchParams(window.location.search);
    const enableStore = Env.ENABLE_STORE || search.get("force-enable-store") === "5";

    return (
        <Layout
            className="layout"
            style={{
                paddingTop: "64px"
            }}
        >
            <AppHeader
                enableStore={enableStore}
            />
            
            <AppContent>
                {children}
            </AppContent>

            <AppFooter />
        </Layout>
    );
}

function layoutMain<T extends React.PropsWithChildren<{}>>(Component: React.ComponentType<T>) {
    return (props: T) => (
        <LayoutMain>
            <Component {...props} />
        </LayoutMain>
    );
}

export default LayoutMain;
export { layoutMain };