import Text from 'antd/lib/typography/Text';
import AppStore from "services/stores/AppStore";

function FlashQueryNotification() {
    AppStore.history.useFlashQuery(params => {
        if (!params.has("success") || (!params.get("message") && !params.get("error"))) return false;
        
        const success = params.get('success') == "1";
        const title = params.get('title');
        if (success) {
            const message = params.get('message')
            AppStore.notifications.showSuccess(<Text strong>{title ?? "Success!"}</Text>, message);
        } else {
            const message = params.get('error');
            AppStore.notifications.showError(<Text strong>{title ?? "Error!"}</Text>, message);
        }

        return true;
    });
    return null;
}

export default FlashQueryNotification;
