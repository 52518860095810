import { useEffect, useState } from "react";
import GoldAnalytics from "services/analytics/GoldAnalytics";
import { useGetStoreGoldBundlesQuery } from "services/api/GraphqlTypes";
import AppStore from "services/stores/AppStore";

function FlashQueryAnalytics() {
    useStoreAnalytics();
    useGoldAnalytics();
    return null;
}

export default FlashQueryAnalytics;

const paymentParamName = "itids";

function useGoldAnalytics() {
    const [bundleIds, setBundleIds] = useState<number[]>([]);
    const { data, loading } = useGetStoreGoldBundlesQuery();

    AppStore.history.useFlashQuery(params => {
        if (!params.has(paymentParamName)) return false;
        let itemIds = params.get(paymentParamName) ?? "";
        itemIds = decodeURIComponent(itemIds);
        itemIds = atob(itemIds);
        const ids = itemIds.split(",").map(x => parseInt(x));
        setBundleIds(ids);
        return true;
    });

    useEffect(() => {
        if (!loading && data?.goldBundles && bundleIds.length) {
            data.goldBundles
                .filter(x => bundleIds.includes(x.id))
                .forEach(bundle => GoldAnalytics.purchase(bundle));
        }
    }, [loading, data, bundleIds]);

    return data;
}

function useStoreAnalytics() {
    
}
