import { Button, Card, Row, Space, Col, Statistic, Tag, Progress, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useLogoutMutation } from '../../../services/api/GraphqlTypes';
import useUser from '../../../services/hooks/useUser';
import FlexSpace from '../../Helpers/FlexSpace';
import LoginWidget from '../LoginWidget';
import Text from 'antd/lib/typography/Text';
import { ArrowRightOutlined, InfoCircleOutlined, LogoutOutlined } from '@ant-design/icons';
import PurchaseGoldModal from '../../Store/PurchaseGoldModal/PurchaseGoldModal';
import usePurchaseGoldModal from '../../Store/PurchaseGoldModal/usePurchaseGoldModal';
import GoldIcon from 'components/Common/GoldIcon';
import Link from 'antd/lib/typography/Link';
import UserRankModal from './UserRankModal';
import PurchaseGoldButton from 'components/Store/PurchaseGoldButton';

interface Props {

}

const UserWidget: React.FC<Props> = () => {
    const [user, loading, { onLogout }] = useUser();
    const [logoutMutation, { loading: logoutLoading }] = useLogoutMutation({
        onError: () => { },
        onCompleted: _ => onLogout()
    });
    
    if (!user) {
        return <LoginWidget />;
    }

    return (
        <Card
            size="small"
            title={<Row>
                <Title
                    level={5}
                    style={{ marginBottom: 0, marginRight: 8 }}
                >Welcome {user.name}</Title>

                <Button
                    size="small"
                    type="link"
                    style={{ marginRight: 6 }}
                    loading={logoutLoading}
                    disabled={logoutLoading}
                    onClick={() => logoutMutation()}
                    icon={<LogoutOutlined />}
                />

                <FlexSpace />

                <GoldIcon suffix={user.gold?.toLocaleString()} />
            </Row>}
            style={loading ? { textAlign: "center" } : undefined}
        >
            <Row wrap gutter={[0, 24]}>
                <Col span={24} style={{ textAlign: "center" }}>
                    <Statistic
                        title="Current Rank"
                        valueRender={() => {
                            const length = user.closeRanks.length;
                            const index = user.closeRanks.indexOf(user.rank);
                            const finalRanks = [];

                            if (index - 1 >= 0) {
                                finalRanks.push(user.closeRanks[index - 1]);
                            }

                            finalRanks.push(user.rank);

                            if (index + 1 < length) {
                                finalRanks.push(user.closeRanks[index + 1]);
                            } else if (index - 2 >= 0) {
                                finalRanks.unshift(user.closeRanks[index - 2]);
                            }

                            if (finalRanks.length < 3 && index + 2 < length) {
                                finalRanks.push(user.closeRanks[index + 2]);
                            }

                            return (
                                <Space>
                                    {finalRanks.map((r, i) => (
                                        <React.Fragment key={i}>
                                            <Tag className="mr-0" color={r == user.rank ? "green" : undefined}>{r}</Tag>
                                            {i < finalRanks.length - 1 && <ArrowRightOutlined />}
                                        </React.Fragment>
                                    ))}
                                </Space>
                            );
                        }}
                        valueStyle={{ fontSize: 14 }}
                    />
                </Col>

                {user.nextRankGoldRequirement ? (
                    <Col span={24} style={{ textAlign: "center" }}>
                        <Statistic
                            title={<>
                                Next Rank Progress
                                <Tooltip title="Spend gold to progress through the ranks and unlock new game content!">
                                    <InfoCircleOutlined
                                        style={{
                                            color: "#10518e",
                                            marginLeft: 6,
                                            cursor: "help"
                                        }}
                                    />
                                </Tooltip>
                            </>}
                            valueRender={() => (
                                <Progress
                                    type="circle"
                                    percent={user.goldSpent / (user.nextRankGoldRequirement || 1) * 100}
                                    success={{
                                        strokeColor: "green"
                                    }}
                                    format={() => (
                                        <Space direction="vertical">
                                            <div>
                                                <GoldIcon /> {(user.nextRankGoldRequirement - user.goldSpent).toLocaleString()}
                                            </div>
                                            <Text type="secondary" style={{ fontSize: 16 }}>Left</Text>
                                        </Space>
                                    )}
                                />
                            )}
                        />
                    </Col>
                ) : null}

                <Col span={24} style={{ textAlign: "center" }}>
                    <UserRankModal
                        renderAction={open => (
                            <Link
                                onClick={open}
                            >
                                View Rank Benefits
                            </Link>
                        )}
                    />
                </Col>

                <Col span={24} style={{ textAlign: "center" }}>
                    <PurchaseGoldButton/>
                </Col>

            </Row>
        </Card>
    );
}

export default UserWidget;