import React from "react";
import styled from "styled-components";

interface Props {
    steps: string[];
    currentStep: number;
}

const Steps = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
    max-width: 368px;
    margin: 0 auto;
`;

const StepLabel = styled.div`
    color: #dac1f2;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    white-space: nowrap;
    opacity: .3;

    &.active {
        opacity: 1;
    }
`;

const Step = styled.div`
    position: relative;
    width: 27px;
    height: 27px;

    &::after, &::before {
        content: "";
        position: absolute;
        display: block;
        border-radius: 50%;
        top: 50%;
        left: 50%;
    }

    &::before {
        width: 7px;
        height: 7px;
        margin-top: -3px;
        margin-left: -3px;
        background: #663399;
    }

    &::after {
        width: 13px;
        height: 13px;
        margin-top: -6px;
        margin-left: -6px;
        border: 1px solid #663399;
    }

    &.active {
        &::before {
            background: #a453f6;
        }

        &::after {
            border-width: 2px;
        }

        ${StepLabel} {
            opacity: 1;
        }
    }
`;

const StepLine = styled.div`
    height: 1px;
    width: 100%;
    background: #8348bd;
    margin: 0 12px;
`;

function ProgressSteps({ steps, currentStep }: Props) {
    return (
        <Steps>
            {steps.map((step, index) => {
                const isActive = index === currentStep;
                return (
                    <React.Fragment key={step + index}>
                        <Step key={index} className={isActive ? "active" : ""}>
                            <StepLabel>
                                {step}
                            </StepLabel>
                        </Step>
                        {index !== steps.length - 1 && (
                            <StepLine />
                        )}
                    </React.Fragment>
                );
            })}
        </Steps>
    );
}

export default ProgressSteps;
