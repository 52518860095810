import { Footer } from "antd/lib/layout/layout";
import Env from "env";

function AppFooter() {
    return (
        <Footer
            style={{
                textAlign: "center",
                position: "relative",
                backgroundColor: "transparent",
                zIndex: 100
            }}
        >
            <div className="blured-underlay" />
            {Env.APP_NAME} ©{(new Date().getFullYear())}
        </Footer>
    )
}

export default AppFooter;
