import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import GoldIcon from 'components/Common/GoldIcon';
import FlexSpace from 'components/Helpers/FlexSpace';
import React, { ReactNode, useState } from 'react';
import userRankData from './UserRankData';

interface Props {
    visible?: boolean;
    renderAction?: (open: () => void) => JSX.Element;
}

const gridStyle: React.CSSProperties = {
    width: "100%",
    // height: "40px",
    textAlign: "center"
};

const renderCell = (key: string, text: ReactNode) => {
    return (
        <Card.Grid key={key} style={gridStyle} hoverable={false}>
            {text}
        </Card.Grid>
    );
}

const UserRankModal: React.FC<Props> = ({ renderAction, visible: visibleFromProps }) => {
    const [visible, setVisible] = useState(false);
    const { labels, rankNames, rankCosts } = userRankData;
    return (
        <>
            {renderAction && renderAction(() => setVisible(true))}
            <Modal
                title="Donation Rank Benefits"
                visible={visible || visibleFromProps}
                onCancel={() => setVisible(false)}
                footer={null}
                width="90%"
                style={{ top: 20 }}
                bodyStyle={{
                    backgroundColor: "#303030"
                }}
            >
                <Row gutter={[16, 16]} className="ranks">
                    {rankNames.map(rankName => {
                        const rankNameKey = rankName.toLowerCase();
                        const cost = rankCosts[rankNameKey].toLocaleString();
                        const labelKeys = Object.keys(labels);
                        return (
                            <Col
                                xs={24}
                                md={8}
                                lg={4}
                                style={{ marginBottom: 8 }}
                                key={rankName}
                            >
                                <Card
                                    title={<div style={{display:"flex"}}>{rankName} <FlexSpace/> <GoldIcon suffix={cost}/></div>}
                                    className={rankNameKey}
                                    bodyStyle={{
                                        marginLeft: 0,
                                        width: "calc(100% - 1px)"
                                    }}
                                >
                                    {labelKeys.map(key => {
                                        const label = userRankData.labels[key];
                                        const value = (userRankData.ranks as any)[rankNameKey][key];

                                        const successIcon = <CheckOutlined className="icon success" />;
                                        const errorIcon = <CloseOutlined className="icon error" />;
                                        
                                        if (!value) {
                                            return null;
                                        }

                                        let body: ReactNode = null;                                        
                                        if (typeof value === "boolean") {
                                            body = (<>
                                                {label} {value ? successIcon : errorIcon}
                                            </>);
                                        } else {
                                            body = (<>
                                                {label}: <span className="icon success">{value}</span>
                                            </>);
                                        }

                                        return renderCell(key, body);
                                    }).filter(x => x)}
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Modal>
        </>
    );
}

export default UserRankModal;